import React, { useEffect, useRef, useState } from 'react';
import './components.css';
import { Spacer10, Spacer2, Spacer5 } from './shared';
import { Link, useLocation } from 'react-router-dom';
import { useIsMobile } from './useIsMobile';

export function TopAppBar(): JSX.Element {
  const isMobile = useIsMobile();
  return isMobile ? <TopAppBarMobile /> : <TopAppBarDesktop />;
}

function TopAppBarDesktop(): JSX.Element {
  return (
    <div className="TopSection TopAppBar FlexSpaceBetween">
      {/* Left */}
      <div>
        <Link to="/">
          <img
            className="TopAppBar-leftLogo Clickable"
            src={`/images/logo_with_name.webp`}
            alt="Logo"
          />
        </Link>
      </div>

      {/* Right */}
      <div className="TopAppBar-navContainer">
        <TopBarNavButton text="Home" path="/" />
        <TopBarNavButton text="About" path="/about" />
        <TopBarNavButton text="FAQ" path="/faq" />
        <TopBarNavButton text="Blog" path="/blog" />
        <TopBarNavButton text="Contact" path="/contact" />
      </div>
    </div>
  );
}

function TopAppBarMobile(): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(isOpen ? contentRef.current.scrollHeight : 0);
    }
  }, [isOpen]);

  const location = useLocation();

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  return (
    <div className="TopSection">
      <div className="TopAppBar WhiteBackground">
        <Link to="/">
          <img
            className="TopAppBar-leftLogo Clickable"
            src={`/images/logo_with_name.webp`}
            alt="Logo"
          />
        </Link>

        <img
          className="TopAppBar-leftLogo Clickable"
          src={`/images/${isOpen ? 'close' : 'open'}.svg`}
          alt="Menu"
          onClick={() => setIsOpen(!isOpen)}
        />
      </div>
      <div
        ref={contentRef}
        className={`TopNavDrawer ${isOpen ? 'open' : ''}`}
        style={{
          height: isOpen ? `${contentHeight}px` : '0',
          overflow: 'hidden',
        }}
      >
        <TopBarNavRow text="Home" path="/" />
        <TopBarNavRow text="About" path="/about" />
        <TopBarNavRow text="FAQ" path="/faq" />
        <TopBarNavRow text="Blog" path="/blog" />
        <TopBarNavRow text="Contact" path="/contact" />
      </div>
    </div>
  );
}

function TopBarNavRow({ text, path }: TopBarNavButtonProps): JSX.Element {
  return (
    <Link to={path} style={{ textDecoration: 'none', color: 'inherit', fontWeight: 'normal' }}>
      <div className="TopBarNavRow Flex FlexHorizontalStart BorderSizing Padding-2 Underlined">
        <TopBarNavButton text={text} path={path} />
      </div>
    </Link>
  );
}

function TopBarNavButton({ text, path }: TopBarNavButtonProps): JSX.Element {
  const location = useLocation();
  const currentPath = location.pathname;
  return (
    <Link to={path} style={{ textDecoration: 'none', color: 'inherit', fontWeight: 'normal' }}>
      <div className={`TopBarNavButton${path === currentPath ? ' TopBarNavButton-selected' : ''}`}>
        <span>{text}</span>
      </div>
    </Link>
  );
}

interface TopBarNavButtonProps {
  text: string;
  path: string;
}

export function BottomBar(): JSX.Element {
  const isMobile = useIsMobile();
  return (
    <div className="footer-outer-container">
      <div className="footer-divider-container">
        <div className="footer-divider-line" />
      </div>
      <div className={`footer-container ${isMobile ? 'Padding-Horizontal-5' : ''}`}>
        <div className="top-footer-content">
          <div className="footer-social-icons">
            <a href="https://discord.gg/BCf4Y2PXdQ" target="_blank" rel="noopener noreferrer">
              <img
                className="footer-social-icon-discord"
                src={`/images/discord-mark-white.png`}
                alt="Discord"
              />
            </a>
            <a href="https://twitter.com/ArtDenApp" target="_blank" rel="noopener noreferrer">
              <img
                className="footer-social-icon-twitter"
                src={`/images/x-logo-white.png`}
                alt="X"
              />
            </a>
          </div>

          <div className="footer-link-grid">
            <div className="footer-link-group">
              <div className="footer-link-header">Company</div>
              <div className="footer-sublinks">
                <FooterNavButton text="About" path="/about" />
              </div>
            </div>
            <div className="footer-link-group">
              <div className="footer-link-header">Support</div>
              <div className="footer-sublinks">
                <FooterNavButton text="FAQ" path="/faq" />
                <FooterNavButton text="Contact" path="/contact" />
              </div>
            </div>
            <div className="footer-link-group">
              <div className="footer-link-header">Policy</div>
              <div className="footer-sublinks">
                <FooterNavButton text="Terms" path="/terms-and-conditions" />
                <FooterNavButton text="Privacy" path="/privacy" />
              </div>
            </div>
          </div>
        </div>
        <div className="footer-inner-divider">
          <div className="footer-inner-divider-line" />
        </div>
        <div className="bottom-footer-section">
          <a href="/" className="footer-logo-link">
            <img className="footer-logo" alt="ArtDen Logo" src="/images/logo_with_name_dark.webp" />
          </a>
          <div className="footer-download-buttons">
            <a
              href="https://apps.apple.com/us/app/artden/id1659234106"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-download-button"
            >
              <div className="footer-download-button-text">Download iOS</div>
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.artden.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-download-button"
            >
              <div className="footer-download-button-text">Download Android</div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

interface FooterNavButtonProps {
  text: string;
  path: string;
}

function FooterNavButton({ text, path }: FooterNavButtonProps): JSX.Element {
  return (
    <Link to={path} style={{ textDecoration: 'none', color: 'inherit', fontWeight: 'normal' }}>
      <div className="footer-link Clickable">{text}</div>
    </Link>
  );
}

interface ExpandableRowProps {
  title: string;
  children: React.ReactNode;
}

export function ExpandableRowV2({ title, children }: ExpandableRowProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(isOpen ? contentRef.current.scrollHeight : 0);
    }
  }, [isOpen]);

  return (
    <div
      className={`RoundedCorners-2 BorderSizing LightGreyBackground Padding-4 Margin-Bottom-4 Shared-ExpandableRow ${
        isOpen ? 'open' : ''
      }`}
    >
      <div
        className="Shared-ExpandableRow-header BorderSizing Padding-Vertical-4 TextSize-SectionHeader"
        onClick={toggleOpen}
      >
        {title}
        <span className={`V2-ExpandableRow-arrow ${isOpen ? 'open' : ''}`}>
          <b>&#x2715;</b>
        </span>
      </div>
      <div
        ref={contentRef}
        className={` Shared-ExpandableRow-content${isOpen ? 'open' : ''}`}
        style={{
          height: isOpen ? `${contentHeight}px` : '0',
          overflow: 'hidden',
          transition: 'height 0.3s ease',
        }}
      >
        {children}
      </div>
    </div>
  );
}
