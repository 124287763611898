import React from 'react';
import './Home.BottomContent.css';
import './Home.HeroSection.css';
import './Home.ContentTiles.css';
import '../shared/shared.css';
import { useIsMobile } from '../shared/useIsMobile';

export function HomePage(): JSX.Element {
  return (
    <div>
      <HeroSection />
      <ContentTiles />
      <BottomContent />
    </div>
  );
}

function HeroSection(): JSX.Element {
  const isMobile = useIsMobile();
  return (
    <div className={`HeroSection ${isMobile ? 'HeroSection-mobile' : ''}`}>
      <div className="HeroSection-content">
        <div className="HeroSection-text">
          <h1 className="HeroSection-title">Simple art commissions, for everyone!</h1>
          <p className="HeroSection-body">
            Post and order commissions on the best art commissioning app. For artists and art lovers
            alike to create - together!
          </p>
        </div>
        <img
          className="HeroSection-image"
          src={`/images/home/main_banner_combined.png`}
          alt="Main Banner"
        />
      </div>
      <div className="HeroSection-buttons">
        <a
          href="https://apps.apple.com/us/app/artden/id1659234106"
          target="_blank"
          rel="noopener noreferrer"
          className="HeroSection-button"
        >
          Download iOS
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.artden.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
          target="_blank"
          rel="noopener noreferrer"
          className="HeroSection-button"
        >
          Download Android
        </a>
      </div>
    </div>
  );
}

function ContentTiles(): JSX.Element {
  return (
    <div className="ContentTiles">
      <Tile
        backgroundImage={`/images/home/content_tile_background_1.png`}
        title="Find the perfect artist"
        description="Easily browse and search our marketplace to find artists you love or find someone new!"
        imagePosition="left"
      />
      <Tile
        backgroundImage={`/images/home/content_tile_background_2.png`}
        title="Get notified"
        description="Add artists to your watch list and get notified on posted commissions. You will also be notified when you receive an order or there are any order updates."
        imagePosition="right"
      />
      <Tile
        backgroundImage={`/images/home/content_tile_background_3.png`}
        title="Post on ArtDen, share with your audience"
        description="Create a commission, post it, share it with your audience and collect orders! We will handle payments, order tracking and file hosting for you."
        imagePosition="left"
      />
      <Tile
        backgroundImage={`/images/home/content_tile_background_4.png`}
        title="Stress free payments"
        description="Secure payments through trusted providers, charge back disputes and taxes, we got your back."
        imagePosition="right"
      />
      <Tile
        backgroundImage={`/images/home/content_tile_background_5.png`}
        title="All the things you need, on the go"
        description="Find artists, place orders or manage your posts from anywhere, anytime with the best app for commissioning digital art and creating together."
        imagePosition="left"
      />
      <Tile
        backgroundImage={`/images/home/content_tile_background_6.png`}
        title="Still not convinced?"
        description="Join our Discord community to meet awesome artists, chat with the team or just hangout and see some cool art!"
        imagePosition="right"
        discordLink="https://discord.gg/BCf4Y2PXdQ"
      />
    </div>
  );
}

function Tile({
  backgroundImage,
  foregroundImage,
  title,
  description,
  imagePosition,
  imageAlign,
  discordLink,
}: TileProps): JSX.Element {
  const imageAlignmentClass =
    imageAlign === 'center'
      ? 'centered-image'
      : imageAlign === 'bottom-anchor'
      ? 'bottom-anchor'
      : imageAlign === 'fill'
      ? 'fill'
      : 'top-offset';

  return (
    <div className={`tile ${imagePosition === 'right' ? 'tile-right' : 'tile-left'}`}>
      {imagePosition === 'left' && (
        <div
          className={`background-image-container ${imageAlignmentClass}`}
          style={{ backgroundImage: `url(${backgroundImage})` }}
        >
          {foregroundImage && <img src={foregroundImage} alt="Foreground Image" />}
        </div>
      )}
      <div className="content">
        <div className="title">{title}</div>
        <p className="description">{description}</p>
        {discordLink && (
          <a
            href={discordLink}
            className="discord-button"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/images/discord-mark-white.png" alt="Discord Logo" /> Join the Discord!
          </a>
        )}
      </div>
      {imagePosition === 'right' && (
        <div
          className={`background-image-container ${imageAlignmentClass}`}
          style={{ backgroundImage: `url(${backgroundImage})` }}
        >
          {foregroundImage && <img src={foregroundImage} alt="Foreground Image" />}
        </div>
      )}
    </div>
  );
}

function BottomContent(): JSX.Element {
  return (
    <div className="bottom-content-container">
      {/* Empty column */}
      <div className="empty-column"></div>
      {/* Center column with bottom content */}
      <div className="bottom-content">
        <h2>Start exploring now!</h2>
        <div className="bottom-buttons">
          <a
            href="https://apps.apple.com/us/app/artden/id1659234106"
            target="_blank"
            rel="noopener noreferrer"
            className="BottomContent-button"
          >
            Download iOS
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.artden.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
            target="_blank"
            rel="noopener noreferrer"
            className="BottomContent-button"
          >
            Download Android
          </a>
        </div>
        {/* Character art credit */}
        <div className="footer-art-credit">
          <p>
            Art by{' '}
            <a
              href="https://www.tumblr.com/goddessofdragons-zt"
              target="_blank"
              rel="noopener noreferrer"
            >
              @goddessofdragons
            </a>
          </p>
        </div>
      </div>
      {/* Right column with explorer image */}
      <div className="bottom-explorer-container">
        <img
          src="/images/home/artden_bottom_explorer.png"
          alt="Explorer"
          className="bottom-explorer-image"
        />
      </div>
    </div>
  );
}

/***
 * backgroundImage: The background image of the tile
 * foregroundImage: The foreground image of the tile overlays on top of the background image (use imageAlign to control alignment)
 * title: The title of the tile
 * description: The description of the tile/body content of the tile
 * imagePosition: The position of the tile image container, either left or right
 * imageAlign: The alignment of the foreground image, either top-offset, center, bottom-anchor or fill
 * discordLink: The discord link to join the discord community, controls visibility of the discord button
 */
interface TileProps {
  backgroundImage: string;
  foregroundImage?: string;
  title: string;
  description: string;
  imagePosition: 'left' | 'right';
  imageAlign?: 'top-offset' | 'center' | 'bottom-anchor' | 'fill';
  discordLink?: string;
}
